import { BrowserRouter } from 'react-router-dom';
import React, { useEffect } from 'react';
import { RecoilRoot } from 'recoil';

import ReactHelmet from './ReactHelmet';
import AdsKeywordStorePage from './AdsKeywordStorePage';
import { MobileFrame } from './MobileFrame';
import { KeyboardFrame } from './KeyboardFrame';
import { LoadingFrame } from './LoadingFrame';
import { ScrollFrame } from './ScrollFrame';
import { InversifyProvider } from './InversifyProvider';
import { container as defaultContainer } from '../inversify.config';
import { isIOS, isMobileOnly, isWindows } from 'react-device-detect';

// @ts-ignore
import { pdfjs } from 'react-pdf';
import MediaQueryProvider from './view/MediaQueryProvider';
import { Container } from 'inversify';
import { CookiesProvider } from 'react-cookie';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const MacTheme = React.lazy(() => import('./themes/MacTheme'));
const WindowTheme = React.lazy(() => import('./themes/WindowTheme'));

interface Postcode {
  new(input: { oncomplete: (data: any) => void }): Postcode;

  open: () => void;
}

declare global {
  interface Window {
    cloudinary: {
      openUploadWidget: (
        options: { cloudName: string, uploadPreset: string },
        handler: (error: any, result: any) => void,
      ) => void,
      createUploadWidget: (
        options: { cloudName: string, uploadPreset: string },
        handler: (error: any, result: any) => void,
      ) => any,
    };
    cloudinaryWidget: {
      open: () => void,
    };
    daum: {
      Postcode: Postcode;
    };
    io: (url: string, options?: any) => any;
    IMP: any;
  }
}

interface RootProps {
  project: string,
  children?: any,
  isDefaultFont?: boolean,
  container?: Container,
}

const ThemeSelector: React.FC<{ isDefaultFont?: boolean, children: any }> = ({ children, isDefaultFont }) => {
  return <>
    <React.Suspense fallback={<></>}>
      {!isDefaultFont && isWindows
        ? <WindowTheme/>
        : <MacTheme/>
      }
    </React.Suspense>
    {children}
  </>;
};

export const RootFrame = ({ project, children, isDefaultFont = true, container = defaultContainer }: RootProps) => {
  return (
    <ThemeSelector isDefaultFont={isDefaultFont}>
      <InversifyProvider container={container}>
        <MediaQueryProvider>
          <CookiesProvider>
            <BrowserRouter>
              <RecoilRoot>
                <ReactHelmet
                  project={project}
                />
                <AdsKeywordStorePage/>
                <MobileFrame/>
                <KeyboardFrame/>
                <LoadingFrame/>
                <ScrollFrame/>
                {children}
              </RecoilRoot>
            </BrowserRouter>
          </CookiesProvider>
        </MediaQueryProvider>
      </InversifyProvider>
    </ThemeSelector>
  );
};
