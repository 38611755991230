import * as _ from 'lodash';
import ReactGA from 'react-ga4';

import { ConversionLogger } from '../domain/ConversionLogger';
import { PathLogger } from '../domain/PathLogger';
import { isProductionMode } from '../helper/Util';

export class GoogleAdsLogger implements ConversionLogger, PathLogger {
  private readonly ids?: string[];
  private readonly eventIds?: string[];
  private readonly isIdExist: boolean;

  constructor(
    idString?: string,
    evnetIdString?: string,
  ) {
    if (idString == null || idString === '') {
      this.isIdExist = false;
      return;
    }
    this.isIdExist = true;

    this.ids = _.split(idString, ';');
    this.eventIds = _.split(evnetIdString, ';');
    if (this.ids?.length === 0) {
      return;
    }
    if (this.ids?.length !== this.eventIds?.length) {
      throw new Error('세팅이 맞지 않습니다.');
    }
    ReactGA.initialize(this.ids.map(id => ({
      trackingId: id,
    })));
    for (const id of this.ids) {
      ReactGA.gtag('js', new Date());
      ReactGA.gtag('config', id);
    }
  }

  logConversionScript() {
    for (const [id, eventId] of _.zip(this.ids, this.eventIds)) {
      if (eventId == null || eventId === '') {
        return;
      }
      // window.dataLayer.push('event', 'conversion', {
      //   send_to: id + '/' + eventId
      // });
      ReactGA.gtag('event', 'conversion', {
        send_to: id + '/' + eventId
      });

      // window.gtag_report_conversion(id, eventId);
    }
  }

  logPath(path: string): void {
    if (this.isValid()) {
      // ReactGA.pageview(path);
      ReactGA.send({ hitType: 'pageview', page: path });
    }
  }

  private isValid(): boolean {
    return isProductionMode() && this.isIdExist;
  }
}
