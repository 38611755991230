import serverConfig from "../config/server.config"
import axios, {
  AxiosRequestConfig,
  AxiosResponse
} from "axios";

class AxiosWrapper
{
  serverMode = 'LOCAL';
  serverUri = "";

  constructor()
  {
    this.serverMode = serverConfig.server_mode;
    switch(this.serverMode)
    {
      case 'LOCAL':
        this.serverUri = `http://127.0.0.1:${serverConfig.server_port}`;
        break;
      case 'DEV':
        this.serverUri = `https://dev-gateway.ailabscorp.com`;
        break;
      case 'LIVE':
        this.serverUri = `https://msa.ailabscorp.com`;
        break;
    }
  }

  get<T = any, R = AxiosResponse<T>>(
    location: string,
    config?: AxiosRequestConfig | undefined
  ): Promise<R>
  {
    return axios.get(this.serverUri + location, config);
  }

  post<T = any, R = AxiosResponse<T>>(
    location: string,
    data?:any,
    config?: AxiosRequestConfig | undefined
  ): Promise<R>
  {
    return axios.post(this.serverUri + location, data, config);
  }
}

export default new AxiosWrapper();