import { Container } from 'inversify';
import 'reflect-metadata';
import ReactGA from 'react-ga4';

import { PHONE_FORMAT_POLICY, PhoneFormatPolicy, TypicalPhoneFormatPolicy } from './domain/PhoneFormatPolicy';
import { REQUEST_SERVICE, RequestService } from './store/RequestStore';
import { KAKAO_SERVICE, KakaoService } from './store/KakaoStore';
import { KAKAO_MESSAGE_SENDER, KakaoMessageSender } from './domain/KakaoMessageSender';
import { ServerKakaoMessageSender } from './infra/ServerKakaoMessageSender';
import { SCRIPT_LOGGER, ScriptLogger } from './domain/ScriptLogger';
import { CombinedLogger } from './infra/CombinedLogger';
import { NaverLogger } from './infra/NaverLogger';
import { GoogleAdsLogger } from './infra/GoogleAdsLogger';
import { KakaoLogger } from './infra/KakaoLogger';
import { DaumReportLogger } from './infra/DaumReportLogger';
import { FacebookPixelLogger } from './infra/FacebookPixelLogger';
import { GoogleAnalyticsLogger } from './infra/GoogleAnalyticsLogger';
import { LOCATION_SERVICE, LocationService } from './store/LocationStore';
import { AirtableRequestRepository } from './infra/AirtableRequestRepository';
import { REQUEST_REPOSITORY, RequestRepository } from './domain/RequestRepository';
import { TAX_AGENT_REQUEST_SERVICE, TaxagentRequestService } from '../taxagent-shared/store/TaxagentRequestStore';
import {
  TAX_AGENT_REQUEST_REPOSITORY,
  TaxagentRequestRepository
} from '../taxagent-shared/domain/TaxagentRequestRepository';
import { AirtableTaxagentRequestRepository } from '../taxagent-shared/infra/AirtableTaxagentRequestRepository';
import { WebSocketManager } from '../startbiz/WebSocketManager';
import { BUILD_REQUEST_REPOSITORY, BuildRepository } from './domain/BuildRepository';
import { AirtableBuildRepository } from './infra/AirtableBuildRepository';
import { FRANCHISE_REQUEST_REPOSITORY, FranchiseRequestRepository } from './domain/FranchiseRequestRepository';
import { AirtableFranchiseRequestRepository } from './infra/AirtableFranchiseRequestRepository';
import { TAX_RETURN_SERVICE, TaxReturnService } from '../taxagent-shared/store/TaxReturnStore';
import { TAX_RETURN_REQUEST_REPOSITORY, TaxReturnReopository } from '../taxagent-shared/domain/TaxReturnReopository';
import { AirtableTaxReturnRepository } from '../taxagent-shared/infra/AirtableTaxReturnRepository';
import {
  PAYMENT_BANK_REPOSITORY,
  PaymentBankRepository
} from '../startbiz/pages/customer/domain/PaymentBankRepository';
import { AirtablePaymentBankRepository } from '../startbiz/pages/customer/infra/AirtablePaymentBankRepository';
import { FRANCHISE_REQUEST_SERVICE, FranchiseRequestService } from './store/FranchiseRequestStore';
import { BuildCustomerService, StartbizCustomerService } from './store/StartbizCustomerService';
import { IamPortCompleteFactory } from '../payment/iam-port/IamPortComplete';
import { PAYMENT_COMPLETE } from '../payment/frame/PaymentCompleteFactory';
import { Payment, PAYMENT_SERVICE } from '../payment/domain/Payment';
import { IamPortPayment } from '../payment/iam-port/IamPortPayment';
import { CustomRequestService } from './service/RequestService';
import { SmartLogger } from './infra/SmartLogger';

const GA_IDs = [{ trackingId: process.env.REACT_APP_GOOGLE_ANALYTICS_ID! }];
if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID_V4 != null) {
  GA_IDs.push({ trackingId: process.env.REACT_APP_GOOGLE_ANALYTICS_ID_V4! });
}
// console.log('GA: ', GA_IDs);
ReactGA.initialize(GA_IDs);
ReactGA.set({ page: window.location.pathname });
ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });

export const container = new Container();
container.bind<PhoneFormatPolicy>(PHONE_FORMAT_POLICY).to(TypicalPhoneFormatPolicy);
container.bind<KakaoMessageSender>(KAKAO_MESSAGE_SENDER).toConstantValue(new ServerKakaoMessageSender(
  process.env.REACT_APP_MESSAGE_API_URL,
));

const adsLogger = new GoogleAdsLogger(
  process.env.REACT_APP_GOOGLE_ADS_ID,
  process.env.REACT_APP_GOOGLE_ADS_BASE_ID,
);
container.bind<ScriptLogger>(SCRIPT_LOGGER).toConstantValue(new CombinedLogger(
  [
    new NaverLogger(),
    adsLogger,
    new KakaoLogger(process.env.REACT_APP_KAKAO_MOMENT_ID),
    new DaumReportLogger(process.env.REACT_APP_DAUM_REPORT_ID),
    new FacebookPixelLogger(),
    new SmartLogger(),
  ],
  [
    adsLogger,
  ],
));
container.bind<RequestRepository>(REQUEST_REPOSITORY).toConstantValue(
  new AirtableRequestRepository(
    process.env.REACT_APP_AIRTABLE_BASE_ID,
    process.env.REACT_APP_AIRTABLE_API_KEY,
    process.env.REACT_APP_AIRTABLE_TABLE_NAME,
    process.env.REACT_APP_AIRTABLE_SITE_TYPE,
  )
);

container.bind<FranchiseRequestRepository>(FRANCHISE_REQUEST_REPOSITORY).toConstantValue(new AirtableFranchiseRequestRepository());

container.bind<BuildRepository>(BUILD_REQUEST_REPOSITORY).toConstantValue(
  new AirtableBuildRepository(
    process.env.REACT_APP_AIRTABLE_BASE_ID,
    process.env.REACT_APP_AIRTABLE_API_KEY,
    process.env.REACT_APP_AIRTABLE_TABLE_NAME,
    process.env.REACT_APP_AIRTABLE_SITE_TYPE,
  )
);

container.bind<TaxagentRequestRepository>(TAX_AGENT_REQUEST_REPOSITORY).toConstantValue(
  new AirtableTaxagentRequestRepository(
    process.env.REACT_APP_AIRTABLE_BASE_ID,
    process.env.REACT_APP_AIRTABLE_API_KEY,
    process.env.REACT_APP_AIRTABLE_TABLE_NAME,
    process.env.REACT_APP_AIRTABLE_SITE_TYPE,
  )
);

container.bind<TaxReturnReopository>(TAX_RETURN_REQUEST_REPOSITORY).toConstantValue(
  new AirtableTaxReturnRepository()
);

container.bind<LocationService>(LOCATION_SERVICE).to(LocationService);
container.bind<KakaoService>(KAKAO_SERVICE).to(KakaoService);
container.bind<RequestService>(REQUEST_SERVICE).to(RequestService);
container.bind<FranchiseRequestService>(FRANCHISE_REQUEST_SERVICE).to(FranchiseRequestService);
container.bind(TAX_AGENT_REQUEST_SERVICE).to(TaxagentRequestService);
container.bind<TaxReturnService>(TAX_RETURN_SERVICE).to(TaxReturnService);
container.bind(WebSocketManager).to(WebSocketManager);
container.bind<PaymentBankRepository>(PAYMENT_BANK_REPOSITORY).to(AirtablePaymentBankRepository);
container.bind<StartbizCustomerService>(StartbizCustomerService).to(StartbizCustomerService);
container.bind<BuildCustomerService>(BuildCustomerService).to(BuildCustomerService);
container.bind(PAYMENT_COMPLETE).to(IamPortCompleteFactory);
container.bind<Payment>(PAYMENT_SERVICE).to(IamPortPayment);
container.bind(CustomRequestService).to(CustomRequestService);
