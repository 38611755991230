import '../shared/helper/IEHelper';

import React from 'react';
import ReactDOM from 'react-dom';
import { Route } from 'react-router-dom';

import '../index.css';
import * as serviceWorker from '../serviceWorker';
import { RootFrame } from '../shared/presentation/RootFrame';
import Manual from '../startbiz/pages/manual/Manual';

ReactDOM.render(
  <RootFrame project={'startbiz'} isDefaultFont>
    <Route component={Manual}/>
  </RootFrame>
  ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
